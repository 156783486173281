/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns"
import firebase from "firebase/compat/app"
import numeral from "numeral"

import {
	calcAPRInterest,
	Card,
	getImageURL,
	NftMetadata,
	NftModel,
	TypeAndIDFilter,
} from "flowty-common"
import noImage from "../../assets/media/image_not_available.svg"
import { getDurationPeriod } from "../../util/nftDataUtil"
import { DEFAULT_TOKEN_TYPE, isFlowRewards } from "../../util/settings"
import { SortableTableField } from "../SortableTable"
import { TokenAmount } from "../Tokens/tokenDisplays"
import { ActivityTableImage } from "./ActivityTableFallbackImage/ActivityTableImage"
import { OverlayValuation } from "./OverlayValuation"

interface ActivityTableData {
	lender: string
	nftType: string
	flowtyStorefrontID: string
	funded: boolean
	listingResourceID: string
	borrower: string
	fundingResourceID: string
	nftID: number
	storefrontAddress: string
	buyer: string
	typeAndIDOffer?: TypeAndIDFilter
	taker?: string
}

export interface ActivityItem {
	id: string
	transactionId: string
	type: string
	activityType: string
	accountAddress: string
	blockchainType: string
	nftInfo?: NftModel
	data: ActivityTableData
	additionalData?: any //TODO remove any
	blockTimestamp: firebase.firestore.Timestamp
	metadata: NftMetadata
	card?: Card
}

// TODO ActivityItem seems to be ListingAvailable or RentalListingAvailable?
// or at least contains some of that metadata?
//maybe itemtype should optionally intersect w those types
export const activityTableFields: (
	navigate: (path: string) => void
) => Array<SortableTableField<ActivityItem>> = navigate => [
	{
		customRender: ({ item }) => {
			const eventType = item.type[0] + item.type.slice(1).toLowerCase()
			return (
				<div className='flex flex-row justify-start items-center'>
					{eventType}
				</div>
			)
		},
		name: "type",
		sortable: false,
		title: "Status",
	},
	{
		customRender: ({ item }) => (
			<div className='flex flex-row justify-start items-center'>
				{item.blockTimestamp
					? format(item.blockTimestamp.toDate(), "yyyy-MM-dd HH:mm")
					: ""}
			</div>
		),
		name: "blockTimestamp",
		sortable: true,
		title: "Start Date",
	},
	{
		customRender: ({ item }) => {
			const itemType = `A.${item.additionalData?.card?.collectionAddress?.substring(
				2
			)}.${item.additionalData?.card?.collectionName}.NFT`

			return (
				<div>
					{!!item.additionalData?.card && (
						<div className='flex flex-row justify-start items-center'>
							<div className='w-[40px] mr-2'>
								<ActivityTableImage
									collectionAddress={
										item?.additionalData?.card?.collectionAddress || ""
									}
									collectionName={
										item?.additionalData?.card?.collectionName || ""
									}
									srcImg={getImageURL(
										item?.additionalData?.card?.images?.[0]?.url ?? noImage,
										isFlowRewards(itemType)
									)}
								/>
							</div>
							<div className='flex flex-col'>
								{item.additionalData?.card?.title ? (
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item.additionalData?.card?.title}
									</span>
								) : (
									<>
										<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
											{item.additionalData?.card?.title}
										</span>
										{item.additionalData?.card?.num &&
											!item.additionalData?.card?.title.includes("#") && (
												<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
													#{item.additionalData?.card?.num}
												</span>
											)}
									</>
								)}
								{item?.additionalData?.card?.additionalDetails ? (
									<>
										{item?.additionalData?.card?.additionalDetails ? (
											item?.additionalData?.card?.additionalDetails?.map(
												(detail: string) => (
													<span
														key={detail}
														className='font-montserrat text-primary text-[12px] font-light'
													>
														{detail}
													</span>
												)
											)
										) : (
											<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
										)}
									</>
								) : (
									<>
										{item?.nftInfo?.card?.headerTraits &&
											item?.nftInfo?.card?.headerTraits?.map(trait => (
												<span
													key={trait.displayType}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{trait.value}
												</span>
											))}
									</>
								)}
							</div>
						</div>
					)}
				</div>
			)
		},
		name: "detail",
		sortable: false,
		title: "Details",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				<TokenAmount
					amount={item.calculatedValues.marketplaceAmount}
					isSmall
					token={item.paymentTokenName}
				/>
			</div>
		),
		name: "amount",
		sortable: false,
		title: "Amount",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{numeral((item.calculatedValues.periodicInterest ?? 0) * 100).format(
					"0,0.00"
				)}
				%
			</div>
		),
		name: "rate",
		sortable: false,
		title: "Rate",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{getDurationPeriod(item.calculatedValues.term)}
			</div>
		),
		name: "duration",
		sortable: false,
		title: "Duration",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{numeral(
					calcAPRInterest(
						item ? Number(item.calculatedValues.periodicInterest) : 0,
						item ? Number(item.calculatedValues.term) : 0
					)
				).format("0,0.00")}
				%
			</div>
		),
		name: "apr",
		sortable: false,
		title: "APR",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				<TokenAmount
					amount={item.calculatedValues.totalRepayment}
					isSmall
					token={item.paymentTokenName || DEFAULT_TOKEN_TYPE}
				/>
			</div>
		),
		name: "repayment",
		sortable: false,
		title: "Repayment Amount",
	},
	{
		customRender: ({ item }: any) => {
			const ltv = Number(
				item?.nftInfo?.valuations?.aggregate?.loanToValueRatio ||
					item?.additionalData?.loanToValueRatio ||
					0
			)

			if (ltv) {
				return (
					<OverlayValuation valuationType='LTV'>
						<div className='flex flex-row justify-start items-center'>
							{Math.round(ltv * 100)}%
						</div>
					</OverlayValuation>
				)
			}
			return <div className='flex flex-row justify-start items-center'>N/A</div>
		},
		name: "valuations.aggregate.loanToValueRatio",
		sortable: false,
		title: "3rd Party LTV",
	},
	{
		customRender: ({ item }: any) => (
			<div
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					navigate(`/user/${item?.data?.lender}`)
				}}
				className='lender flex flex-row justify-start items-center hover:text-green-200'
			>
				<a href={`/user/${item?.data?.lender}`}>{item?.data?.lender || "--"}</a>
			</div>
		),
		name: "lender",
		sortable: false,
		title: "Lender",
	},
	{
		customRender: ({ item }: any) => (
			<div
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					navigate(
						`/user/${item?.data?.borrower || item?.nftInfo?.storefrontAddress}`
					)
				}}
				className='borrower flex flex-row justify-start items-center hover:text-green-200'
			>
				<a
					href={`/user/${
						item?.data?.borrower || item?.nftInfo?.storefrontAddress
					}`}
				>
					{item?.data?.borrower || item?.nftInfo?.storefrontAddress || "--"}
				</a>
			</div>
		),
		name: "borrower",
		sortable: false,
		title: "Borrower",
	},
]
